<template>
  <dl class="ticket-history">
    <dt class="ticket-history__key">ご利用日時</dt>
    <dd class="ticket-history__value">{{ usedAt }}</dd>
    <dt class="ticket-history__key">回数券番号</dt>
    <dd class="ticket-history__value">{{ ticketNumber }}</dd>
  </dl>
</template>

<script>
import moment from '@/assets/lib/moment'

export default {
  mixins: [moment],
  props: {
    // 表示する回数券情報
    ticket: {
      type: Object,
      required: true
    }
  },
  computed: {
    /**
     * @return {String} 利用日時
     */
    usedAt () {
      return this.formatted(this.ticket.usedAt.toDate(), 'YYYY/MM/DD HH:mm')
    },
    /**
     * @return {String} 回数券番号
     */
    ticketNumber () {
      return this.ticket.tiid
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.ticket-history {
  box-sizing: border-box;
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin-left: -$padding_width;
  font-size: 1.2rem;
  text-align: left;
  border-bottom: 1px solid $gray_color;
  &__key {
    float: left;
    width: 60px;
    clear: both;
  }
  &__value {
    margin-left: 80px;
  }
}
</style>
