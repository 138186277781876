<template>
  <div class="ticket-histories" v-if="!isProcessing">
    <template v-if="hasHistory">
      <ticket-history class="ticket-histories__item" v-for="ticket in usedTicketHistories" :key="ticket.tiid" :ticket="ticket" />
    </template>
    <p class="ticket-histories__warning" v-else>ご利用済みの回数券がありません</p>
  </div>
</template>

<script>
import TicketHistory from '@/components/ticket/history'

export default {
  components: { TicketHistory },
  name: 'ticket_histories',
  async mounted () {
    // 回数券の履歴がない場合は取得する
    if (!this.usedTicketHistories.length) await this.$store.dispatch('tickets/getUsedTicketHistories', this.uid)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object[]} 利用済みの回数券の履歴情報
     */
    usedTicketHistories () {
      return this.$store.getters['tickets/usedHistories']
    },
    /**
     * @return {Boolean} 利用履歴があるかどうか
     */
    hasHistory () {
      return this.usedTicketHistories.length
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/size.scss";
@import "@/assets/sass/color.scss";

.ticket-histories {
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
  &__item {
    &:first-child {
      margin-top: -$padding_height;
    }
  }
  &__warning {
    margin: 0;
    font-size: 1.2rem;
    color: $red_color;
    text-align: center;
  }
}
</style>
